/**
 * Created by mateimisarca on 2019-06-11
 */

import React, { Component } from 'react';
import { find, includes, toLower, isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { object } from 'prop-types';
import { bindActionCreators } from 'redux';

import Heading from './../../components/heading';
import InConstructie from './../../components/in-constructie';

import { courses } from '../../constants/courses';

import rating from './../../assets/images/rating.png';

import { coursesActionsFacade as coursesActions } from '../../actions/courses';

import './curs-page.scss';

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      coursesActions,
    ), dispatch),
  };
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class CursPage extends Component {
  static propTypes = {
    match: object,
    user: object,
  };

  constructor(props) {
    super(props);

    const { course } = props.match.params;
    const selectedCourse = find(courses, o => o.link === course);

    const isSubscribed = find(props.user?.subscriptions, o => {
        return includes(toLower(o.cursuri), toLower(selectedCourse?.title)) ||
          isEqual(o.courseId, selectedCourse._id) ||
          (
            includes(selectedCourse?.title, 'Dicție și mișcare scenică') &&
            includes(o.cursuri, 'Dicție și mișcare scenică')
          ) || (
            isEqual(selectedCourse?.title, 'Utilizarea responsabilă a resurselor educaționale digitale') &&
            isEqual(o.cursuri, 'Resurse digitale pentru profesorii de gimnaziu')
          );
      },
    );

    props.actions.getCourse(selectedCourse.link).then(res => {
      if (res?.payload?.course?.display === false) {
        this.setState({
          display: false,
        });
      }
      if (res?.payload?.course?.isRegisterActive === false) {
        this.setState({
          isRegisterActive: false,
        });
      }
      this.setState({
        isActive: res?.payload?.course?.isActive || false,
      });
    });

    this.state = {
      selectedCourse,
      isSubscribed,
      display: true,
      isRegisterActive: true,
      isActive: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location: { pathname },
      match: { params: { course } },
      user,
      actions: { getCourse },
    } = this.props;
    if (pathname !== prevProps.location.pathname) {
      const selectedCourse = find(courses, o => o.link === course);

      const isSubscribed = find(user?.subscriptions, o => includes(toLower(o.cursuri), toLower(selectedCourse?.title)) || (includes(selectedCourse?.title, 'Dicție și mișcare scenică') && includes(o.cursuri, 'Dicție și mișcare scenică')));

      getCourse(selectedCourse.link).then(res => {
        if (res?.payload?.course?.display === false) {
          this.setState({
            display: false,
          });
        }
        if (res?.payload?.course?.isRegisterActive === false) {
          this.setState({
            isRegisterActive: false,
          });
        }
        this.setState({
          isActive: res?.payload?.course?.isActive || false,
        });
      });

      this.setState({
        selectedCourse,
        isSubscribed,
        display: true,
        isActive: false,
      });
    }
  }

  renderTabs() {
    return (
      <div className="course-tab">
        <ul className="service-tab">
          <li className="active">
            <a data-toggle="tab" href="#overview" aria-expanded="true">
              Prezentare
            </a>
          </li>
          {/*
          <li className="">
            <a data-toggle="tab" href="#curriculum" aria-expanded="false">
              Curriculum
            </a>
          </li>
*/ }
          <li className="">
            <a data-toggle="tab" href="#instructor" aria-expanded="false">
              Formator
            </a>
          </li>
          <li className="">
            <a data-toggle="tab" href="#coruse_review" aria-expanded="false">
              Păreri
            </a>
          </li>
          <li className="">
            <a data-toggle="tab" href="#write_review" aria-expanded="false">
              Părerea dvs.
            </a>
          </li>
        </ul>
      </div>
    );
  }

  renderDetails() {
    const {
      locuri,
      durata,
      pret,
      perioadaInscriere,
    } = this.state.selectedCourse.detalii;
    const {
      match: {
        params: {
          course,
        },
      },
      isLoggedIn,
    } = this.props;
    const { isSubscribed, display, isActive, isRegisterActive } = this.state;

    return (
      <div className="event-details-info">
        <h2>Detalii</h2>
        <ul>
          <li>Perioada de înscriere:<span dangerouslySetInnerHTML={ { __html: perioadaInscriere } } /></li>
          <li>Durata:<span>{ durata }</span></li>
          <li>Locuri:<span>{ locuri }</span></li>
          { pret && <li>Pret<span>{ pret }</span></li> }
          <li>Nota:<span><img src={ rating } alt="" /></span></li>
        </ul>
        <div className="get-coruse-btn">
          { display ?
            isLoggedIn ?
              isSubscribed ?
                isActive ? (
                    <Link disabled to={ `/cursuri/${ course }/view-modules` }>PARCURGE CURSUL</Link>
                  ) :
                  null
                :
                (isRegisterActive === true ? (
                  <Link to={ `/inscriere/${ course }` }>INSCRIERE</Link>
                ) : null)
              : (
                <Link to={ `/login` }>Log In</Link>
              ) : null }
        </div>
      </div>
    );
  }

  createMarkup(text) {
    return { __html: text };
  }

  render() {
    const { course } = this.props.match.params;
    const { selectedCourse } = this.state;

    if (!selectedCourse) return null;

    const headingLinks = [
      {
        link: '/',
        text: 'Acasa',
      },
      {
        link: '/cursuri',
        text: 'Cursuri',
      },
      {
        link: `/cursuri/${ course }`,
        text: selectedCourse?.title,
      },
    ];

    return (
      <div className="cursPage">
        <Heading title={ selectedCourse.title } links={ headingLinks } image={ selectedCourse.banner } />
        <div className="course-details-page pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                { this.renderTabs() }
              </div>
              <div className="col-md-6">
                <div className="tab-content">

                  <div id="overview" className="tab-pane active">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="course-details">
                          <img
                            style={ {
                              display: 'flex',
                              margin: '0 auto',
                            } }
                            src={ selectedCourse.courseImg }
                            alt={ selectedCourse?.title }
                          />
                          { selectedCourse.details.map((text, index) => (
                            <div key={ index }>
                              <h2>{ text.title }</h2>
                              <div className="cursPage-descriere"
                                   dangerouslySetInnerHTML={ this.createMarkup(text.text) } />
                            </div>
                          )) }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="curriculum" className="tab-pane">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="faq-sec">
                          <div className="panel-group" id="accordion" role="tablist">
                            <InConstructie />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="instructor" className="tab-pane">
                    <div className="row">
                      <div className="col-md-12">
                        { selectedCourse.trainers ?
                          selectedCourse.trainers.map((text, index) => (
                            <div key={ index }>
                              <h2>{ text.title }</h2>
                              <div className="cursPage-descriere"
                                   dangerouslySetInnerHTML={ this.createMarkup(text.text) } />
                            </div>
                          ))
                          : (<InConstructie />) }
                      </div>
                    </div>
                  </div>

                  <div id="coruse_review" className="tab-pane">
                    <div className="row">
                      <div className="col-md-12">
                        <InConstructie />
                      </div>
                    </div>
                  </div>

                  <div id="write_review" className="tab-pane">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="review-form">
                          <InConstructie />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                { this.renderDetails() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
