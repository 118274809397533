/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import { upperFirst }       from 'lodash';

export default class CourseItem extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
  }

  renderDetails() {
    const {texts} = this.props;
    const {open} = this.state;

    if (!open) {
      return (
        <div onClick={() => this.setState({open: !open})}>
          <a href="#" onClick={e => e.preventDefault()}>
            [vezi mai mult]
          </a>
        </div>
      );
    }

    return (
      <div>
        {texts.map((text, index) => (
          <div key={index}>
            <h3>{text.title}</h3>
            <p>{text.text}</p>
          </div>
        ))}
        <div onClick={() => this.setState({open: !open})}>
          <a href="#" onClick={e => e.preventDefault()}>
            [vezi mai putin]
          </a>
        </div>
      </div>
    );
  }

  render() {
    const {title, durata, link, bullet, courseImg, activ, hide} = this.props;

    if (hide) return null;

    return (
      <div className="course-inner">
        <div className="course-thumb">
          <img src={courseImg} alt="" />
          <div className="teacher-thumb">
            <img src={bullet} alt="" />
          </div>
          {activ && (
            <div className="readmore-button">
              <Link to={`/cursuri/${link}`}>Detalii</Link>
            </div>
          )}
        </div>
        <div className="course-meta">
          <span className="course-price">&nbsp;</span>
          <span className="course-rating">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                    </span>
        </div>
        <div className="course-desc">
          <h2>{upperFirst(title)}</h2>
          {this.renderDetails()}
        </div>
        <div className="course-info">
          <ul>
            <li><i className="fa fa-user" style={{color: '#f1c40f'}} />&nbsp;Online</li>
            <li><i className="fa fa-clock-o" style={{color: '#f1c40f'}} />&nbsp;{durata}</li>
            {/*<li><a href="#"><i className="fa fa-heart" />Save</a></li>*/}
          </ul>
        </div>
      </div>
    );
  }
}

